import { Box, Divider, Drawer, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { LuCopy, LuCopyCheck } from 'react-icons/lu';
import { getPayoutById } from '../../services/operators';
import { formatDateTime } from '../../utils/dateFormater';
import { getPayoutStatusTextColor } from '../../utils/getStatusColor';
import { Payouts } from '../../utils/types';
import classes from '../../pages/Settings/Settings.module.css';
const HistoryPayoutModal = ({
  id,
  open,
  onClose,
  onKeyDown,
  onClick,
}: {
  id: string;
  open: boolean;
  onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
  onKeyDown: (event: React.KeyboardEvent | React.MouseEvent) => void;
  onClick: (event: React.KeyboardEvent | React.MouseEvent) => void;
}) => {
  const [payout, setPayout] = useState<Payouts | null>(null);
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);


  useEffect(() => {
    const fetch = async () => {
      const data = await getPayoutById(Number(id));
      if (data) {
        setPayout(data);
      }
    };
    if (open) {
      fetch();
    }
  }, [open]);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const status = {
    pending: 'PENDING',
    processing: 'PROCESSING',
    canceled: "CANCELED"
  }

  return (
    <Drawer anchor={'right'} open={open} onClose={onClose}>
      <Box role="presentacion" onClick={onClick} onKeyDown={onKeyDown}>
        <Box sx={{ p: 3 }}>
          <Typography sx={{ mb: 3 }} level="h4">
            {t('details_payout_header')}
          </Typography>
          {!payout && (
            <Typography sx={{ mb: 3 }} level="body-md">
              {t('not_found')}
            </Typography>
          )}
          {payout && (
            <>
              <Box>
                <Typography level="title-lg" sx={{ color: "#947EFE", mb: 2 }}>
                  {t('details_payout_name')}
                </Typography>
                <Typography sx={{ mr: '6px', overflowWrap:'anywhere' }} level="body-md">{`ID: `} {payout.id}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                  <Typography level="body-md" sx={{ wordBreak: 'break-word', flex: 1 }}>
                    {'Hashed ID: '} {payout.hashed_id}
                  </Typography>
                  <CopyToClipboard text={payout.encrypted_id} onCopy={onCopyText}>
                  <span className={classes.iconCopy}>
                    {(isCopied && <LuCopyCheck size={17} />) || <LuCopy size={17} />}
                  </span>
                  </CopyToClipboard>
                </Box>

                <Typography level="body-md">
                  {`Order id: ${payout.order_id}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_startDate')}: ${formatDateTime(payout.created_at)}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_endDate')}: ${formatDateTime(payout.updated_at)}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_course')}: ${parseFloat(payout.merchant_course.toString()).toFixed(
                    2
                  )}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_priceUah')}: ${parseFloat(payout.amount.toString()).toFixed(2)}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_priceUsdt')}: ${parseFloat(payout.merchant_amount.toString()).toFixed(
                    2
                  )}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('feeTax', {
                    tax: Number(payout.merchant_fee.toString()).toFixed(2)
                  })}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('fee', {
                    tax: parseFloat(payout.merchant_reward.toString()).toFixed(
                      2
                    )
                  })}`}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  <Typography level="body-md">{t('history_table_status') + ':'}</Typography>
                  <Typography
                    level="body-md"
                    sx={{ color: getPayoutStatusTextColor(payout.status) }}
                  >
                    {payout?.status &&
                      payout.status.charAt(0).toUpperCase() +
                      payout.status.slice(1).toLowerCase()}
                  </Typography>
                </Box>
                {!(payout.status === status.pending || payout.status === status.processing || payout.status === status.canceled) && (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      mb: 2,
                    }}
                  >
                    {payout.status_changed_by && (
                      <>  <Typography level="body-md">{t('history_table_status_changedBy') + ':'}</Typography>
                    <Typography
                      level="body-md"
                      sx={{ color: "#f1f1f1" }}
                    >
                      {payout.status_changed_by}
                        </Typography>
                      </>  
                    )}
                 </Box>
                )}
                <Divider />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default HistoryPayoutModal;

import { Typography } from '@mui/joy';
import React from 'react';
import { formatDateTime } from '../../../utils/dateFormater';
import { getStatusTextColor } from '../../../utils/getStatusColor';
import { Withdrawals } from '../../../utils/types';

const WithdrawalItem = ({ item }: { item: Withdrawals }) => {
  return (
    <>
      <tr key={item.id}>
        <td>
          <Typography level="body-xs" sx={{ color: "#F1F1F1" }}>{formatDateTime(item.createdAt)}</Typography>
        </td>
        <td style={{ textAlign: 'center' }}>
          <Typography
            level="body-md"
            sx={{ color: getStatusTextColor(item.status), fontWeight: 700 }}
          >{item?.status &&
            item.status.charAt(0).toUpperCase() +
            item.status.slice(1).toLowerCase()}
          </Typography>
        </td>
        <td style={{ textAlign: 'center' }}>
          <Typography level="body-md" sx={{ color: "#F1F1F1" }}>{Number(item.requested_amount).toFixed(2)}</Typography>
        </td>
        <td style={{ textAlign: 'center' }}>
          <Typography level="body-md" sx={{ color: "#F1F1F1" }}>{Number(item.settled_amount).toFixed(2)}</Typography>
        </td>
        <td style={{ textAlign: 'center' }}>
          <Typography level="body-md" sx={{ color: "#F1F1F1" }}>{Number(item.settlement_fee_amount).toFixed(2)}</Typography>
        </td>
        <td>
          <Typography level="body-xs" sx={{ color: "#F1F1F1" }}>{formatDateTime(item.updatedAt)}</Typography>
        </td>
        <td style={{ textAlign: 'center' }}>
          <Typography level="body-xs"
                      sx={{ overflowWrap: 'anywhere', color: "#F1F1F1" }}>{item.personal_wallet}</Typography>
        </td>
      </tr>
    </>
  );
};
export default WithdrawalItem;
